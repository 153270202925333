import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ILoginPayload, IRegisterPayload } from "types/auth";
import { login, register } from "../../api/authRquests";
import { setMessage } from "./messageSlice";
import { AppThunk } from "../../store";
import { getUserDetails } from "./userSlice";
import { LocalStorageItems } from "../../constants/localStorageItems";
import Cookies from "js-cookie";
import { COOKIE_DOMAIN } from "../../config/env-constants";

interface AuthState {
  isLoggedIn: boolean;
  loading: boolean;
  error: string;
}

interface ILoginResponse {
  jwt: string;
}

export const loginUser = createAsyncThunk<
  ILoginResponse,
  ILoginPayload,
  { rejectValue: AxiosError }
>("auth/login", async (credentials: ILoginPayload, { rejectWithValue, dispatch }) => {
  try {
    const response = await login(credentials);
    const { status, data } = response;
    const token = data.jwt;
    if (status === 200) {
      localStorage.setItem(LocalStorageItems.TOKEN, token);
    }
    await dispatch(getUserDetails());
    dispatch(
      setMessage({
        type: "success",
        message: "You have successfully authenticated !",
      })
    );
  } catch (err) {
    const error = err as AxiosError;
    dispatch(
      setMessage({
        type: "error",
        message: "There was an error while logging in ! Please try again !",
      })
    );
    return rejectWithValue(error);
  }
});

export const registerUser = createAsyncThunk<
  IRegisterPayload,
  IRegisterPayload,
  { rejectValue: AxiosError }
>("auth/register", async (registrationData: IRegisterPayload, { rejectWithValue, dispatch }) => {
  try {
    const { data } = await register(registrationData);
    dispatch(
      setMessage({
        type: "success",
        message: "You have successfully registered !",
      })
    );
    return data;
  } catch (err) {
    const error = err as AxiosError;
    dispatch(
      setMessage({
        type: "error",
        message: error.message,
      })
    );
    return rejectWithValue(error);
  }
});

export const logoutUser = (): AppThunk => {
  return (dispatch) => {
    Cookies.remove(LocalStorageItems.TOKEN, { domain: COOKIE_DOMAIN });
    console.log("logoutUser -> remove token");
    dispatch(clearState());
    dispatch(
      setMessage({
        type: "success",
        message: "You was successful logged out !",
      })
    );
  };
};
const initialState: AuthState = {
  isLoggedIn: false,
  loading: false,
  error: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },

    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },

    clearState: (state) => {
      state.isLoggedIn = false;
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(loginUser.fulfilled, (state) => {
      state.loading = false;
      state.isLoggedIn = true;
    });
    builder.addCase(loginUser.rejected, (state, { payload }: PayloadAction<AxiosError>) => {
      state.loading = false;
      state.error = payload.message;
    });
  },
});

const { actions, reducer } = authSlice;

export const { setLoading, setErrors, clearState } = actions;

export const authSelector = (state: { authStore: AuthState }) => state.authStore;

export default reducer;
